//更改用户状态信息
export const userStatus = (state, user) => {
    if (user) {
        state.currentUser = user.username;
        state.currentUserId = user.userId
        state.userRole = user.role
        state.isLogin = true
    } else if (user == null) {
        state.currentUser = null;
        state.isLogin = false;
        state.userImage = null;
        state.userRole = null;
        sessionStorage.clear()
    }
}

export const userRole = (state, role) => {
    if (role) {
        state.userRole = role;
    }
}

export const userEntity = (state, userEntity) => {
    if (userEntity) {
        state.userEntity = userEntity;
    }
}

export const startTime = (state, startTime) => {
    if (startTime) {
        state.startTime = startTime
    }
}

export const endTime = (state, endTime) => {
    if (endTime) {
        state.endTime = endTime
    }
}

export const selectedDetail = (state, row) => {
    if (row) {
        let obj = state.selectedDetail
        if (obj === null) {
            obj = {}
        }
        for (let key in row) {
            obj[key] = row[key]
        }
        state.selectedDetail = obj
    }
}


export const selectedPage = (state, row) => {
    if (row) {
        let obj = state.selectedPage
        if (obj === null) {
            obj = {}
        }
        for (let key in row) {
            obj[key] = row[key]
        }
        state.selectedPage = obj
    }
}

export const Authorization = (state, Authorization) => {
    if (Authorization) {
        state.Authorization = Authorization
    }
}


export const siSelectedPage = (state, row) => {
    if (row) {
        state.siSelectedPage = row
    }
}

export const siSelectedDetail = (state, row) => {
    if (row) {
        state.siSelectedDetail = row
    }
}


export const PUSH_CANCEL = (state, cancel) => {
    state.axiosCancelArr.push(cancel.cancelToken);
}

export const CLEAR_CANCEL = (state) => {
    state.axiosCancelArr.forEach(e => {
        e && e()
    });
    state.axiosCancelArr = []
}
