import Vue from 'vue'
import App from './App.vue'
import './assets/css/icon.css';
import router from './router' //引入路由配置
import store from './store/store' //引入 Vuex 状态管理
import './assets/css/globalcss.css'

// 完整加载
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

import 'default-passive-events'
import Vant from 'vant'
import 'vant/lib/index.css';
Vue.use(Vant)


Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router, //使用路由配置
    store //使用 Vuex 进行状态管理
}).$mount('#app')
