<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    created() {
      //在页面加载时读取sessionStorage里的状态信息
      if (this.$store.getters.userEntity == null) {
        if (sessionStorage.getItem("store") != null) {
          this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))))
        }
      }

      // 在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener("beforeunload", () => {
        if (this.$store.getters.userEntity != null) {
          sessionStorage.setItem("store", JSON.stringify(this.$store.state))
        } else {
          sessionStorage.clear()
          // this.$router.push({path: "/login"})
        }
      })
    },
  }
</script>

<style>
  @import "./assets/css/main.css";
  @import "./assets/css/color-dark.css";     /*深色主题*/
  html,
  body,
  #app {
    height: 100%;
    overflow: auto !important;
    background-color: #e8f4ff;
  }

</style>
