//应用mutation
export const setUser = ({commit},user) => {
    commit("userStatus",user)
}

export const setUserRole =  ({commit},role) => {
    commit("userRole",role)
}

export const setUserEntity =  ({commit},userEntity) => {
    commit("userEntity",userEntity)
}

export const setStartTime =  ({commit},startTime) => {
    commit("startTime",startTime)
}
export const setEndTime =  ({commit},endTime) => {
    commit("endTime",endTime)
}

export const setSelectedDetail =({commit},selectedDetail) => {
    commit("selectedDetail",selectedDetail)
}

export const setSelectedPage =({commit},selectedPage) => {
    commit("selectedPage",selectedPage)
}

export const setAuthorization =  ({commit},Authorization) => {
    commit("Authorization",Authorization)
}

export const setSiSelectedPage =({commit},selectedPage) => {
    commit("siSelectedPage",selectedPage)
}

export const setSiSelectedDetail =({commit},selectedPage) => {
    commit("siSelectedDetail",selectedPage)
}


export const pushCancel = ({commit}, cancel)=>{
    commit('PUSH_CANCEL', cancel)
}

export const clearCancel = ({commit})=>{
    commit('CLEAR_CANCEL');
}
