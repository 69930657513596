export const currentUser = state => state.currentUser

export const currentUserId = state => state.currentUserId

export const userRole = state => state.userRole

export const userEntity = state => state.userEntity

export const startTime = state =>state.startTime

export const endTime = state=>state.endTime


export const selectedPage = state=>state.selectedPage

export const selectedDetail = state=>state.selectedDetail


export const Authorization = state =>state.Authorization

export const siSelectedPage = state=>state.siSelectedPage

export const siSelectedDetail= state=>state.siSelectedDetail

export const get_is_log_existed = state=>state.is_log_existed
