import Vue from 'vue' //引入 Vue
import VueRouter from 'vue-router'
import store from "@/store/store";

Vue.use(VueRouter); //安装插件

export const constantRouterMap = [
    {
        path: '/',
        redirect: '/index'
    },
    //配置登录成功页面，使用时需要使用 path 路径来实现跳转
    {path: '/index', name: 'mainpage', component: () => import('@/components/views/main')},
    // {path: '/index', name: 'index', component: () => import('@/components/views/index')},
    {path: '/lucky/:lotteryId', name: 'lucky', component: () => import('@/components/views/lucky')},
    {path: '/my', name: 'myinfo', component: () => import('@/components/views/my')},
    {path: '/free', name: 'myfree', component: () => import('@/components/views/free')},
    {path: '/lucky-list', name: 'luckylist', component: () => import('@/components/views/lucky_list')},
    {path: '/lucky-record', name: 'luckyrecord', component: () => import('@/components/views/lucky_records')},
]

const router = new VueRouter({
    // mode: 'history', //后端支持可开
    scrollBehavior: () => ({y: 0}),
    routes: constantRouterMap //指定路由列表
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    store.dispatch('clearCancel');
    next();
});



export default router;

